import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
import {
  setStorageData,
  getStorageData,
} from "../../../framework/src/Utilities";
import { handleNavigation } from "../../../components/src/CommonFunctions";
import { v4 as uuidv4 } from "uuid";

export interface ServiceList {
  id: number
  value: string
  name: string
  price: string
  type: string
  petType: string
  serviceType?: string
}

interface Service {
  value: string;
  service_provided_type: string;
  charge_type: string;
  name: string;
  id: number;
  price: string;
  hotelId: string;
  additionalServiceId: string;
  type: string;
  petType: string,
}
interface LinkObject {
  url: string;
  title: string;
}
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
};
interface OwnService {
  name: string,
  price: string,
  service_provided_type: string
};

interface DayCareServiceAttributes {
  price_for_one_day: string;
  price_for_two_days: string;
  price_for_three_days: string;
  price_for_four_days: string;
  price_for_five_days: string;
  price_for_six_days: string;
  price_for_seveen_days: string;
  weekly_price_for_one_day: string;
  weekly_price_for_two_days: string;
  weekly_price_for_three_days: string;
  weekly_price_for_four_days: string;
  weekly_price_for_five_days: string;
  weekly_price_for_six_days: string;
  weekly_price_for_seveen_days: string;
}

interface DayCareServiceData {
  id: string;
  type: string;
  attributes: DayCareServiceAttributes;
}

interface DayCareServiceResponse {
  day_care_service: {
    data: DayCareServiceData;
  }
}
import { ChangeEvent } from "react";
import { CreateYourOwnService, HotelAdditionalService, ServiceItem } from "../../dashboard/src/types";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  id: string;
  navigation: any;
  messageId: string;
  // Customizable Area End
}

interface S {
  // Customizable Area StartactiveStep: number;
  options: any[];
  optionsNotice: any[];
  activeStep: number;
  anchorEl: boolean;
  anchorElInFrom: Boolean;
  anchorElInUntill: Boolean;
  anchorElOutFrom: Boolean;
  anchorElOutUntill: Boolean;
  anchorElBefore: null | EventTarget;
  anchorElMin: null | EventTarget;
  anchorElMax: null | EventTarget;
  anchorElNote: null | EventTarget;
  selectedServices: any[];
  priceInputs: any[];
  isadditionalServicesError: boolean;
  additionalServicesError: any;
  modelOpen: boolean;
  optionsCheckIn: any[];
  optionsCheckOut: any[];
  serviceYouSelect: any[];
  dogAdditionalPrice: string | number;
  catAdditionalPrice: string | number;
  serviceAdditionalPrice: string;
  addRoomDog: number;
  addRoom: number;
  selectedPet: string;
  checkIn_Out: boolean;
  nightStay: boolean;
  priceOnePet: number | string;
  priceTwoPet: number | string;
  serviceFee: number | string;
  cleaningFee: number | string;
  roomType: string;
  descriptionRoom: string;
  checkInFrom: string;
  checkInUtill: string;
  checkOutFrom: string;
  checkOutUtill: string;
  selectedOptionMin: string;
  selectedOptionMax: string;
  advanceNotice: string;
  noticeBefore: string;
  roomData: any[];
  deleteSelectRoomId: string;
  removeModel: boolean;
  removePetId: null | number;
  hotel_id: number;
  dilogeOpen: boolean;
  firstPageError: boolean;
  pet_type: any;
  roomPricesArray: any[];
  errorRoomPricesArray: any[];
  roomDetail: any[];
  saveDraftWarn: boolean;
  saveDraftWarnSec: boolean;
  profileIdBo: number | null
  isClear: boolean;
  isMix: boolean;
  errorMsg: string
  errorMsgModel: string;
  catRoomDog: number;
  ownServiceText: any;
  createOwServiceErrorMessage: string
  priceErrorMessage: string;
  errorMsgRoomType: string,
  errorMsgPetType: string;
  errorMsgDescription: string
  errorMsgRoomCount: string
  errorPetCount: string,
  ownPrice: any[],
  hotelId: any,
  errorMsgRoomPrice: string;
  ownServices: Array<OwnService>;
  oneTimePrice: string[];
  weeklyPrice: string[];
  anchorElMenu: any
  socialLinksData: { icons: string, navigationUrl: string }[];
  poBOLinksData: LinkObject[]
  spaces: string;
  myOwnServices: Service[];
  isDeleted: string;
  additionalServices: HotelAdditionalService[];
  ownServicesList: CreateYourOwnService[]
  newAdditionalServices: HotelAdditionalService[];
  newelyCreateOwnServices: CreateYourOwnService[]
  editedAdditionalServices: HotelAdditionalService[],
  editedOwnServices: CreateYourOwnService[],
  additionalInputErrors: { [key: string]: string };
  ownServiceInputErrors: { [key: string]: string };
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class BussinessOwnerDetailsThird extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addHotelCallId: string = "";
  roomDataGetId: string = "";
  deleteRoomId: string = "";
  additional_serviceDataId: string = "";
  addRoomCallId: string = "";
  profileId: string = "";
  customServiceCallId: string = "";
  dailyCareId: string = "";
  getHotelCallId: string = ""
  hotelAddApiCallId: string = ""
  apiCallIdCreateOwnServices: string = "";
  apiCallIdDeleteService: string = "";
  apiCallIdUpdateCreateService: string = "";
  apiCallIdUpdateTimings:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.BussinessOwnerDetailsThirdMessage),
      getName(MessageEnum.BussinessOwnerDetailsThirdInfo),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      options: [],
      activeStep: 2,
      errorMsg: "",
      errorMsgModel: "",
      anchorEl: false,
      anchorElInFrom: false,
      anchorElInUntill: false,
      anchorElOutFrom: false,
      anchorElOutUntill: false,
      anchorElBefore: null,
      anchorElMin: null,
      anchorElMax: null,
      anchorElNote: null,
      selectedServices: [],
      priceInputs: [],
      additionalServicesError: {},
      isadditionalServicesError: false,
      modelOpen: false,
      optionsCheckIn: ["08:00 AM", "08:30 AM", "09:00 AM", "09:30 AM", "10:00 AM",],
      serviceYouSelect: [],
      optionsCheckOut: ["18:00", "18:30", "19:00", "19:30", "20:00"],
      dogAdditionalPrice: "",
      catAdditionalPrice: "",
      serviceAdditionalPrice: "",
      addRoomDog: 0,
      addRoom: 0,
      selectedPet: "",
      checkIn_Out: false,
      nightStay: false,
      priceOnePet: "",
      priceTwoPet: "",
      serviceFee: "",
      cleaningFee: "",
      roomType: "",
      descriptionRoom: "",
      checkInFrom: "08:00",
      checkInUtill: "11:30",
      checkOutFrom: "18:00",
      checkOutUtill: "19:30",
      selectedOptionMin: "1 day",
      selectedOptionMax: "30 day",
      advanceNotice: "Same day",
      noticeBefore: "08:00",
      optionsNotice: [
        "Same Day",
        "At least 1 Day",
        "At least 2 Days",
        "At least 3 Days",
        "At least 7 Days",
      ],
      roomData: [],
      deleteSelectRoomId: "",
      removeModel: false,
      removePetId: null,
      hotel_id: 0,
      dilogeOpen: false,
      firstPageError: false,
      pet_type: "daycare_services",
      roomPricesArray: [],
      errorRoomPricesArray: [],
      roomDetail: [],
      saveDraftWarn: false,
      saveDraftWarnSec: false,
      profileIdBo: null,
      isClear: false,
      isMix: true,
      catRoomDog: 0,
      ownServiceText: [],
      createOwServiceErrorMessage: "",
      priceErrorMessage: "",
      errorMsgRoomType: "",
      errorMsgPetType: "",
      errorMsgDescription: "",
      errorMsgRoomCount: "",
      hotelId: "",
      errorPetCount: "",
      ownPrice: [],
      errorMsgRoomPrice: "",
      anchorElMenu: {},
      ownServices: [],
      oneTimePrice: [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      weeklyPrice: [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      socialLinksData: [],
      poBOLinksData: [],
      spaces: "",
      myOwnServices: [],
      isDeleted: "",
      additionalServices: [],
      ownServicesList: [],
      newAdditionalServices: [],
      newelyCreateOwnServices: [],
      editedAdditionalServices: [],
      editedOwnServices: [],
      additionalInputErrors: {},
      ownServiceInputErrors: {}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const sessionData1 = message.getData(getName(MessageEnum.HotelDetails));
      if (sessionData1) {
        this.setState({ activeStep: sessionData1.activeStep, hotelId: sessionData1.hotelId }, () => {
          this.getHotel()
          setStorageData("hotelId", sessionData1.hotelId)
        })
      }
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.addHotelCallId) {
      this.callOwnServiceApi();
      if (this.state.saveDraftWarn) {
        this.props.navigation.navigate("PropertyDetails")
      } else {

        localStorage.removeItem("allDataBoFirstStep")
        localStorage.removeItem("service_type")
        localStorage.removeItem("pet_type")
        localStorage.removeItem("firstFormThirdData")
        localStorage.removeItem("selectedImagess")
        localStorage.removeItem("allDataBoSecondStep")
        localStorage.removeItem("BussinessOwnerDetailsSecondBack")
        localStorage.removeItem("BussinessOwnerDetailsThirdBack")
        localStorage.removeItem("selectedImages")
        localStorage.removeItem("selectedServices")
        localStorage.removeItem("timeServices")
        localStorage.removeItem("additionalPrice")
        localStorage.removeItem("allDataBoThirdStep")
        localStorage.removeItem("BussinessOwnerDetailsFourBack")
        sessionStorage.removeItem("firstFormData")
        sessionStorage.removeItem("secondFormData")
        sessionStorage.removeItem("BussinessOwnerDetailsFourBack")

        this.props.navigation.navigate("CancellationPolicyFirst")
      }
    } else if (apiRequestCallId === this.roomDataGetId) {
      this.getRoomsResponse(responseJson)

    } else if (apiRequestCallId === this.deleteRoomId) {
      this.roomDataGet();
    } else if (apiRequestCallId === this.additional_serviceDataId) {

      this.setState({
        options: responseJson.data.sort((a: any, b: any) => parseInt(a.attributes.id) - parseInt(b.attributes.id))
      });


    } else if (apiRequestCallId === this.addRoomCallId) {
      this.handleAddRoomResponse(responseJson)
    } else if (apiRequestCallId === this.dailyCareId) {
      this.dayCareSuccessCallBack(responseJson);
    } else if (apiRequestCallId === this.apiCallIdDeleteService) {
      if (responseJson && !responseJson.errors) {
        this.getHotel()
      }
    }
    if (apiRequestCallId === this.getHotelCallId) {
      this.getHotelApiResponse(responseJson)
    }
    if(apiRequestCallId===this.apiCallIdUpdateTimings){
      this.getHotel()

    }

    this.storagefunction()

    // Customizable Area End
  }

  // Customizable Area Start

  getRoomsResponse = (responseJson: any) => {
    if (responseJson) {
      const roomDetails = responseJson?.map((room: any) => {
        const roomDetail: {
          descriptionRoom: any;
          selectedPet: any;
          price: any;
          roomType: any;
          pet_count: any;
          add_pet: any;
          checkInFrom: any;
          checkInUtill: any;
          checkOutFrom: any;
          checkOutUtill: any;
          selectedOptionMinimum: any;
          selectedOptionMaximum: any;
          advanceNotice: any;
          noticeBefore: any;
          roomPricesArray: { name: string; price: number }[];
          addRoom: any;
          addRoomDog?: any;
          catRoomDog?: any;
        } = {
          descriptionRoom: room.description,
          selectedPet: room.room_for,
          price: room.price,
          roomType: room.room_type,
          pet_count: room.pet_count,
          add_pet: room.add_pet,
          checkInFrom: room.check_in_start,
          checkInUtill: room.check_in_end,
          checkOutFrom: room.check_out_start,
          checkOutUtill: room.check_out_end,
          selectedOptionMinimum: room.min_stay,
          selectedOptionMaximum: room.max_stay,
          advanceNotice: room.advance_notice,
          noticeBefore: room.notice_before,
          roomPricesArray: room.pet_room_prices.map((price: number, index: number) => ({
            name: `${this.numberToWord(index + 1)}`,
            price,
          })),
          addRoom: Number(room.num_rooms),
        };

        if (room.room_for === 'Dog') {
          roomDetail.addRoomDog = Number(room.max_capacity);
        } else {
          roomDetail.catRoomDog = Number(room.max_capacity);
        }

        return roomDetail;
      }) || [];

      this.setState({
        roomDetail: roomDetails,
      });
    }
  };




  getHotelApiResponse = (responseJson: any) => {
    if (responseJson) {
      const hotelData = responseJson?.data?.attributes
      const services = hotelData["Create your own services"];

      const additionalServices = hotelData?.hotel_additional_services?.map((service: HotelAdditionalService) => ({
        id: service.id,
        service_provided_type: service.service_provided_type,
        serviceId: service.id,
        name: service.name,
        price: service.price,
        charge_type: service.charge_type === "All" ? "AllPets" : "PerPet",
      })) || [];
      const ownServicesList: CreateYourOwnService[] = services.map((service: CreateYourOwnService) => ({
        id: service.id,
        service_provided_type: service.service_provided_type,
        serviceId: service.id,
        name: service.name,
        price: service.price,
        charge_type: service.charge_type === "All" ? "AllPets" : "PerPet",
        alreadyCreated: true
      })) || [];


      this.setState({
        additionalServices: additionalServices, ownServicesList: ownServicesList, pet_type: hotelData.pet_type,
        editedOwnServices: [], editedAdditionalServices: [], newAdditionalServices: [], newelyCreateOwnServices: [],
        checkIn_Out: responseJson.data?.attributes?.check_in_check_out_times_present,
        checkInFrom: responseJson.data?.attributes?.check_in_start,
        checkInUtill: responseJson.data?.attributes?.check_in_end,
        checkOutFrom: responseJson.data?.attributes?.check_out_start,
        checkOutUtill: responseJson.data?.attributes?.check_in_end,
      })
    }
  }


  handleAddRoomResponse = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      if (this.state.saveDraftWarn || this.state.saveDraftWarnSec) {
        this.props.navigation.navigate("PropertyDetails")
      }
    }
  }

  storagefunction = () => {
    const firstFormDataString = sessionStorage.getItem("firstFormData");

    if (firstFormDataString !== null) {
      const parsedData = JSON.parse(firstFormDataString);

      if (parsedData.pet_type === "dog_hotel") {
        this.setState({
          selectedPet: "Dog"
        });
      } else {
        this.setState({
          selectedPet: "Cat"
        });
      }

    }
  }




  async componentDidMount() {

    const hotelId = JSON.parse(await getStorageData("hotelId"))
    const pet_type = JSON.parse(await getStorageData("pet_type"))
    this.setState({ hotelId: hotelId, pet_type: pet_type });


    const selectedProfilePoId = localStorage.getItem("profileIdBo");
    this.setState({
      profileIdBo: Number(selectedProfilePoId)
    })

    this.additional_serviceData();
    const mySocialLinksData = await getStorageData("footerNavigationUrl", true);
    this.setState({ socialLinksData: mySocialLinksData });
    const allLinksData = await getStorageData("BOandPOLinks", true)
    this.setState({ poBOLinksData: allLinksData })
  }

  getAllData = async () => {
    const { saveDraftWarnSec, saveDraftWarn } = this.state;
    const allData = JSON.parse(await getStorageData("allDataBoThirdStep")) || {};


    if (saveDraftWarnSec === false) {
      this.updateStateForSaveDraftSec(allData);
    }
    if (saveDraftWarn === false) {
      this.updateStateForSaveDraft(allData);
    }
  };


  updateStateForSaveDraftSec = (allData: { checkInFrom: string; checkInUtill: string; checkOutFrom: string; checkOutUtill: string; selectedOptionMin: string; selectedOptionMax: string; advanceNotice: string; noticeBefore: string; }) => {
    this.setState({
      checkInFrom: allData.checkInFrom,
      checkInUtill: allData.checkInUtill,
      checkOutFrom: allData.checkOutFrom,
      checkOutUtill: allData.checkOutUtill,
      selectedOptionMin: allData.selectedOptionMin,
      selectedOptionMax: allData.selectedOptionMax,
      advanceNotice: allData.advanceNotice,
      noticeBefore: allData.noticeBefore
    });
  };

  updateStateForSaveDraft = (allData: any) => {
    this.setState({
      dogAdditionalPrice: allData.dogAdditionalPrice,
      catAdditionalPrice: allData.catAdditionalPrice,
      serviceAdditionalPrice: allData.serviceAdditionalPrice || "",
      serviceYouSelect: allData.serviceYouSelect,
      roomPricesArray: allData.roomPricesArray || [],
      priceInputs: allData.priceInputs || [],

      roomDetail: allData.roomDetail || []
    });
  };

  handleAdditinalServicesChange = (indexs: number, value: any) => {
    const updatedSelectedService = [...this.state.selectedServices];

    if (typeof updatedSelectedService[indexs] === 'string') {
      updatedSelectedService[indexs] = { value };
    }

    this.setState({ selectedServices: updatedSelectedService });
  };


  timeServices = async () => {
    const timeServices = JSON.parse(await getStorageData("timeServices"));


    timeServices && (this.setState({
      checkInFrom: timeServices.checkInFrom || "08:00",
      checkInUtill: timeServices.checkInUtill || "11:30",
      checkOutFrom: timeServices.checkOutFrom || "18:30",
      checkOutUtill: timeServices.checkOutUtill || "19:00",
      selectedOptionMin: timeServices.selectedOptionMin || "1 day",
      selectedOptionMax: timeServices.selectedOptionMax || "30 day",
      advanceNotice: timeServices.advanceNotice || "Same day",
      noticeBefore: timeServices.noticeBefore || "08:00",

    }))
  }
  additionalPrice = async () => {
    const additionalPrice = JSON.parse(await getStorageData("additionalPrice"));

    additionalPrice && (this.setState({
      dogAdditionalPrice: additionalPrice.dogAdditionalPrice,
      catAdditionalPrice: additionalPrice.catAdditionalPrice,
      serviceAdditionalPrice: additionalPrice.serviceAdditionalPrice || "",
      serviceYouSelect: additionalPrice.serviceYouSelect,
      roomPricesArray: additionalPrice.roomPricesArray,
      roomDetail: additionalPrice.roomDetail,
      priceInputs: additionalPrice.priceInputs,

    }))
  }

  allDataSave = () => {

    const allDataBoThirdStep = {
      dogAdditionalPrice: this.state.dogAdditionalPrice,
      catAdditionalPrice: this.state.catAdditionalPrice,
      serviceAdditionalPrice: this.state.serviceAdditionalPrice,
      serviceYouSelect: this.state.serviceYouSelect,
      roomPricesArray: this.state.roomPricesArray,
      roomDetail: this.state.roomDetail,
      checkInFrom: this.state.checkInFrom,
      checkInUtill: this.state.checkInUtill,
      checkOutFrom: this.state.checkOutFrom,
      checkOutUtill: this.state.checkOutUtill,
      selectedOptionMin: this.state.selectedOptionMin,
      selectedOptionMax: this.state.selectedOptionMax,
      advanceNotice: this.state.advanceNotice,
      noticeBefore: this.state.noticeBefore,
      priceInputs: this.state.priceInputs,

    }

    setStorageData("allDataBoThirdStep", JSON.stringify(allDataBoThirdStep));
  }

  saveDraftData = () => {
    if (this.state.activeStep === 1 || this.state.activeStep === 2) {

      if (this.state.roomDetail.length === 0) {
        this.setState({
          errorMsg: "Please add atleast one room type*",

        })
      } else {
        this.setState({
          saveDraftWarn: true
        }, () => this.handleValidation(2))
      }




      this.handleDilogeClose();
    } else if (this.state.activeStep === 3) {
      const timeServices = {
        checkInFrom: this.state.checkInFrom,
        checkInUtill: this.state.checkInUtill,
        checkOutFrom: this.state.checkOutFrom,
        checkOutUtill: this.state.checkOutUtill,
        selectedOptionMin: this.state.selectedOptionMin,
        selectedOptionMax: this.state.selectedOptionMax,
        advanceNotice: this.state.advanceNotice,
        noticeBefore: this.state.noticeBefore,
      };

      this.setState({
        saveDraftWarnSec: true
      }, () => this.state.roomDetail.forEach((value) => {
        this.validateStep3(value);
        this.updateTimings()
      }))

      setStorageData("timeServices", JSON.stringify(timeServices));
      this.handleDilogeClose();
    } else if (this.state.activeStep === 4) {
      this.setState({ saveDraftWarn: true }, () => {
        if (this.state.newelyCreateOwnServices.length > 0) {
          this.createOwnServices()
        } else if (this.state.newAdditionalServices.length > 0 || this.state.editedAdditionalServices.length > 0 || this.state.editedOwnServices.length > 0) {
          this.updateAdditionalServices()
        }
      })
      this.handleDilogeClose();
    }
  };

  saveDraftModelOpen = () => {
    this.setState({
      dilogeOpen: true,
    });
  };

  handleDilogeClose = () => {
    this.setState({
      dilogeOpen: false
    });
  };

  formInputChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^\d.]/g, '');

    if (value === "Dog") {
      this.setState({
        dogAdditionalPrice: numericValue,
        firstPageError: false,
      });
    } else if (value === "Cat") {
      this.setState({
        catAdditionalPrice: numericValue,
        firstPageError: false,
      });
    } else if (value === "Service") {
      this.setState({
        serviceAdditionalPrice: numericValue,
      });
    }
  };
  handleCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    const { checked } = event.target;
    const { serviceYouSelect } = this.state;

    if (checked) {
      if (!serviceYouSelect.includes(value)) {
        this.setState({
          serviceYouSelect: [...serviceYouSelect, value],
        });
      }
    } else {
      this.setState({
        serviceYouSelect: serviceYouSelect.filter((item) => item !== value),
      });
    }
  };

  handleClickModelOpen = () => {
    this.setState({
      modelOpen: true,
      errorMsg: ""
    });
  };

  handleModelClose = () => {
    const petSelected = this.state.pet_type === "cat_hotel" ? "Cat" : "Dog";
    this.setState({
      modelOpen: false,
      roomType: "",
      descriptionRoom: "",
      selectedPet: petSelected,
      addRoomDog: 0,
      roomPricesArray: [],
      serviceFee: "",
      cleaningFee: "",
      errorMsg: "",
      addRoom: 0,
      catRoomDog: 0,
      errorMsgModel: "",
      errorMsgPetType: "",
      errorMsgRoomType: "",
      errorMsgDescription: "",
      errorMsgRoomCount: "",
      errorPetCount: ""
    });
  };
  handleCheckIn_Out = (value: boolean) => {
    this.setState({
      checkIn_Out: value
    });
  };

  handleNightStay = (value: boolean) => {
    this.setState({
      nightStay: value
    });
  }



  handlePriceChange = (id: number, event: React.ChangeEvent<HTMLInputElement>, serviceName: string) => {
    const priceValues = event.target.value;




    let tempAdditionalServicesError: any = { ...this.state.additionalServicesError };
    tempAdditionalServicesError[id] = priceValues;
    const numericValues = priceValues.replace(/[^\d.]/g, '');






    this.setState((prevState) => {
      const updatedPriceInput = [...prevState.priceInputs];
      const existingIndex = updatedPriceInput.findIndex(
        (item) => item.id === id
      );



      if (existingIndex !== -1) {
        updatedPriceInput[existingIndex].value = numericValues;
      } else {

        updatedPriceInput.push({ id: id, value: numericValues, name: serviceName });
      }
      return {

        anchorEl: false,
        createOwServiceErrorMessage: "",
        isadditionalServicesError: false,
        priceInputs: updatedPriceInput,



        additionalServicesError: tempAdditionalServicesError,
        selectedServices: prevState.selectedServices.map(service => {
          if (service.name === serviceName) {
            return { ...service, price: numericValues };
          }
          return service;
        })



      };
    },);


  };


  handleBack = () => {
    this.getHotel()
    if (this.state.activeStep === 1 || (this.state.pet_type === "daycare_services" && this.state.activeStep === 2)) {
      handleNavigation("BussinessOwnerDetailsSecond", { activeStep: 3, hotelId: this.state.hotelId }, this.send, this.props)
    } else {
      this.setState({ activeStep: this.state.activeStep - 1 });
    }
  };
  handleSelect = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({ anchorEl: true, errorMsg: "" });
  };
  handleSelectClick = (event: React.MouseEvent<HTMLDivElement>, type: string) => {
    if (event) {
      if (type === "checkInFrom") {
        this.setState({
          anchorElInFrom: true,
        });
      } else if (type === "checkInUntil") {
        this.setState({
          anchorElInUntill: true,
        });
      } else if (type === "checkOutFrom") {
        this.setState({
          anchorElOutFrom: true,
        });
      } else if (type === "checkOutUntil") {
        this.setState({
          anchorElOutUntill: true,
        });
      } else if (type === "noticeBefore") {
        this.setState({
          anchorElBefore: event.currentTarget,
        });
      } else {
        this.setState({
          anchorElInFrom: false,
          anchorElInUntill: false,
          anchorElOutFrom: false,
          anchorElOutUntill: false,
          anchorElBefore: null,
        });
      }
    }
  };

  handleSelectClickMin = (event: React.MouseEvent<HTMLDivElement>, type: string) => {
    if (type === "min") {
      this.setState({ anchorElMin: event.currentTarget });
    } else if (type === "max") {
      this.setState({ anchorElMax: event.currentTarget });
    }
  };

  handleSelectClickNote = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({ anchorElNote: event.currentTarget });
  };

  minusdogsRoomOnClick = () => {
    const { selectedPet } = this.state;
    if (selectedPet === 'Dog' && this.state.addRoomDog > 0) {
      const updatedRoomPricesArray = this.state.roomPricesArray.slice(0, -1);
      this.setState({
        addRoomDog: this.state.addRoomDog - 1,
        roomPricesArray: updatedRoomPricesArray,
      });
    } else if (selectedPet === 'Cat' && this.state.catRoomDog > 0) {
      const updatedRoomPricesArray = this.state.roomPricesArray.slice(0, -1);
      this.setState({
        catRoomDog: this.state.catRoomDog - 1,
        roomPricesArray: updatedRoomPricesArray,
      });
    }
  };

  minusdogsRoomOnClickData = (index: number) => {
    this.setState((prev) => ({
      roomDetail: prev.roomDetail.map((room, i) => {
        if (i === index && room.addRoomDog > 0) {
          const updatedRoomPricesArray = room.roomPricesArray.slice(0, -1);
          return { ...room, addRoomDog: room.addRoomDog - 1, roomPricesArray: updatedRoomPricesArray };
        }
        if (i === index && room.catRoomDog > 0) {
          const updatedRoomPricesArray = room.roomPricesArray.slice(0, -1);
          return { ...room, catRoomDog: room.catRoomDog - 1, roomPricesArray: updatedRoomPricesArray };
        }
        return room;
      }),
    }));
  };


  numberToWord = (number: number) => {
    const words = ["one", "two", "three", "four", "five", "six",
      "seven", "eight", "nine", "ten", "", "eleven", "twelve", "thirteen",
      "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen", "twenty",
      "twenty-one", "twenty-two", "twenty-three", "twenty-four", "twenty-five"];

    return words[number - 1] || number.toString();
  };



  adddogsRoomOnClick = () => {
    const { selectedPet } = this.state;

    // Determine which room type to update based on the selected pet
    const roomTypeToUpdate = selectedPet === 'Cat' ? 'catRoomDog' : 'addRoomDog';

    this.setState((prevState) => ({
      ...prevState,
      [roomTypeToUpdate]: prevState[roomTypeToUpdate] + 1,
      roomPricesArray: [
        ...prevState.roomPricesArray,
        {
          name: `${this.numberToWord(prevState[roomTypeToUpdate] + 1)}`,
          price: null,
        },
      ].filter((room, index, self) =>
        index === self.findIndex((r) => r.name === room.name)
      ),
      petErrorMessage: ""
    }));
  };


  adddogsRoomOnClickData = (index: number) => {



    this.setState((prev) => ({
      roomDetail: prev.roomDetail.map((room, i) => {
        if (i === index) {
          const existingRoomPricesArray = Array.isArray(room.roomPricesArray) ? room.roomPricesArray : [];
          let addDog = 0;
          let addCat = 0;
          if (room.selectedPet === 'Cat' || room.selectedPet === "cat") {
            addCat = Number(room.catRoomDog) + 1
          } else {
            addDog = Number(room.addRoomDog) + 1
          }
          let pet = addDog === 0 ? addCat : addDog;
          const roomPricesArray = [
            ...existingRoomPricesArray,
            {
              name: `${this.numberToWord(pet)}`,
              price: null,
            },
          ].filter((r, index, self) => index === self.findIndex((room) => r.name === room.name));

          return { ...room, addRoomDog: addDog, catRoomDog: addCat, roomPricesArray };
        }
        return room;
      }),
    }));


  };
  addMorePropertyRoom = (index: number) => {

    this.setState((prev) => ({
      roomDetail: prev.roomDetail.map((room, i) => {
        if (i === index) {
          const existingRoomPricesArray = Array.isArray(room.roomPricesArray) ? room.roomPricesArray : [];
          let catRoomDog
          let addRoomDog
          let addRoom
          addRoom = room.addRoom + 1

          const roomPricesArray = [
            ...existingRoomPricesArray,

          ].filter((r, index, self) => index === self.findIndex((room) => r.name === room.name));

          return { ...room, addRoom, roomPricesArray };
        }
        return room;
      }),
    }));
  };

  minusMorePropertyRoom = (index: number) => {

    this.setState((prev) => ({
      roomDetail: prev.roomDetail.map((room, i) => {
        if (room.addRoom > 0) {
          if (i === index) {
            const existingRoomPricesArray = Array.isArray(room.roomPricesArray) ? room.roomPricesArray : [];
            let addRoom
            addRoom = room.addRoom - 1
            const roomPricesArray = [
              ...existingRoomPricesArray,

            ].filter((r, index, self) => index === self.findIndex((room) => r.name === room.name));

            return { ...room, addRoom, roomPricesArray };
          }
        }
        return room;
      }),
    }));
  };


  minusRoomOnClick = () => {
    if (this.state.addRoom > 0) {
      this.setState({ addRoom: this.state.addRoom - 1 });
    }
  };


  addRoomOnClick = () => {
    this.setState({ addRoom: this.state.addRoom + 1 });
  };


  handleRadioChange = (petType: string) => {
    this.setState({
      selectedPet: petType,
    });
  };

  handleRadioChangeData = (petType: string, index: number) => {
    this.setState((prev) => ({
      roomDetail: prev.roomDetail.map((room, i) => (i === index ? { ...room, selectedPet: petType } : room)),
    }));
  };

  roomSelector = (event: React.ChangeEvent<{ value: unknown; }>) => {
    const { value } = event.target;
    if (value !== undefined && typeof value === 'string') {
      this.setState({
        roomType: value,
      });
    }
  }

  roomSelectorData = (event: React.ChangeEvent<{ value: unknown; }>, index: number) => {
    const { value } = event.target;

    this.setState((prev) => ({
      roomDetail: prev.roomDetail.map((room, i) => (i === index ? { ...room, roomType: value } : room)),
    }));
  };

  priceHandler = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement | { value: string; }>, type: string) => {
    const { value } = event.target;
    const numericValue = value.replace(/[^\d]/g, '');

    if (type === "service") {
      this.setState({
        serviceFee: numericValue,
      });
    } else if (type === "cleaning") {
      this.setState({
        cleaningFee: numericValue,
      });
    } else if (type === "descriptionRoom") {
      this.setState({
        descriptionRoom: value,
      });
    }

  };

  priceHandlerData = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement | { value: string; }>, type: string, index: number) => {
    const { value } = event.target;
    const numericValue = value.replace(/[^\d]/g, '');

    this.setState((prev) => ({
      roomDetail: prev.roomDetail.map((room, i) => {
        if (i === index) {
          if (type === "service") {
            return { ...room, serviceFee: numericValue };
          } else if (type === "cleaning") {
            return { ...room, cleaningFee: numericValue };
          } else if (type === "descriptionRoom") {
            return { ...room, descriptionRoom: value };
          }
        }
        return room;
      }),
    }));
  };

  priceHandlers = (event: React.ChangeEvent<HTMLInputElement>, petType: string) => {

    const priceValue = event.target.value.replace("£", "").trim();
    const regex = configJSON.regex;

    if (priceValue.length > 20) {
      this.setState({
        errorMsgRoomPrice: "Price should be limited",
      });

    } else {
      this.setState((prevState) => {
        const updatedRoomPricesArray = prevState.roomPricesArray.map((room) => {
          if (room.name === petType && regex.test(priceValue)) {
            return {
              ...room,
              price: priceValue,
            };
          }
          return room;
        });

        return {
          ...prevState,
          errorMsgModel: "",
          roomPricesArray: updatedRoomPricesArray,
          errorMsgRoomPrice: ""
        };
      });
    }
  };

  priceHandlersData = (event: React.ChangeEvent<HTMLInputElement>, petType: string, index: number) => {
    const priceValue = event.target.value.replace("£", "").trim();
    const regex = configJSON.regex;

    this.setState((prevState) => ({
      roomDetail: prevState.roomDetail.map((room, i) => {
        if (i === index) {
          const updatedRoomPricesArray = room.roomPricesArray.map((price: { name: string; }) => {
            if (price.name === petType && regex.test(priceValue)) {
              return {
                ...price,
                price: priceValue,
              };

            }
            return price;
          });

          return {
            ...room,
            roomPricesArray: updatedRoomPricesArray,
          };

        }
        return room;
      }),
    }));
  };


  handleClosePopup = () => {
    this.setState({ anchorEl: false });

  }


  handleSelectClose = (values: string, type: string) => {
    if (type === "checkInFrom") {
      this.setState({
        checkInFrom: values,
      }, () => {
        this.closePopUp()
      });
    } else if (type === "checkInUntil") {
      this.setState({
        checkInUtill: values,
      },
        () => {
          this.closePopUp()
        });
    } else if (type === "checkOutFrom") {
      this.setState({
        checkOutFrom: values,
      }, () => {
        this.closePopUp()
      });
    } else if (type === "checkOutUntil") {
      this.setState({
        checkOutUtill: values,
      }, () => {
        this.closePopUp()
      });
    } else if (type === "noticeBefore") {
      this.setState({
        anchorElBefore: null,
        noticeBefore: values,
      });
    } else {
      this.setState({
        anchorElInFrom: false,
        anchorElInUntill: false,
        anchorElOutFrom: false,
        anchorElOutUntill: false,
        anchorElBefore: null,
      });
    }
  };

  closePopUp = () => {

    this.setState({
      anchorElInFrom: false, anchorElInUntill: false, anchorElOutFrom: false, anchorElOutUntill: false
    });

  }
  handleSelectCloseMin = (value: string) => {
    if (this.state.anchorElMin) {
      this.setState({
        anchorElMin: null,
        selectedOptionMin: value,
      });
    } else if (this.state.anchorElMax) {
      this.setState({
        anchorElMax: null,
        selectedOptionMax: value,
      });
    } else {
      this.setState({ anchorElMin: null, anchorElMax: null });
    }
  };

  handleSelectCloseNote = (value: string) => {
    if (value) {
      this.setState({
        anchorElNote: null,
        advanceNotice: value,
      });
    } else {
      this.setState({ anchorElNote: null });
    }
  };



  saveDataModel = () => {

    let isError = false;
    let isPropertiesError = false;
    if (
      this.state.roomType === "" ||
      this.state.descriptionRoom === "" ||
      this.state.addRoom < 1 ||
      (this.state.addRoomDog < 1 && this.state.catRoomDog < 1) ||
      (this.state.pet_type === "cat_hotel" && this.state.selectedPet === "Dog")
    ) {
      isError = true;
    }




    let newRoomPricesArray = this.state.roomPricesArray.map((item) => {




      if (!item.price) {
        isError = true;
      }
      return {
        ...item,
        isError: !item.price ? true : false
      }
    }

    );
    if (isError) {
      this.setState({
        errorRoomPricesArray: newRoomPricesArray,
        errorMsgModel: configJSON.withoutpriceErrorMessage,
        errorMsgPetType: "Please set the pet type name",
        errorMsgRoomType: "Please enter the room type name",
        errorMsgDescription: "Please enter the room description",
        errorMsgRoomCount: "Please specify the number of rooms at the property",
        errorPetCount: "Please specify the maximum number of pets in a room"
      })
    } else {
      this.setState((prev) => ({
        roomDetail: [
          ...prev.roomDetail,
          {
            roomType: this.state.roomType,
            descriptionRoom: this.state.descriptionRoom,
            selectedPet: this.state.selectedPet,
            addRoomDog: this.state.addRoomDog,
            roomPricesArray: this.state.roomPricesArray,
            serviceFee: this.state.serviceFee,
            cleaningFee: this.state.cleaningFee,
            addRoom: this.state.addRoom,
            catRoomDog: this.state.catRoomDog,
            errorMsgModel: "",
            errorMsgRoomType: "",
            errorMsgPetType: "",
            errorMsgDescription: "",
            errorMsgRoomCount: "",
            errorPetCount: "",


          }
        ],
        activeStep: 2
      }))


      this.handleModelClose();
    }




  };

  goToNextStep = (nextStep: number) => {

    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "CancellationPolicyFirst");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  };

  handleDayCareService = () => {
    let myFlag = false;
    if (this.state.spaces === "") {
      myFlag = true;
    }
    this.state.oneTimePrice.forEach((price: string) => {
      if (price === "") {
        myFlag = true;
      }
    })
    this.state.weeklyPrice.forEach((price: string) => {
      if (price === "") {
        myFlag = true;
      }
    })
    if (!myFlag) {
      this.dayCareServiceApiCall();
    } else {
      this.setState({
        errorMsg: "Please enter all the fields.",
      })
    }
  }

  handleNext = () => {
    const { activeStep } = this.state;
    if (activeStep === 1) {
      this.setState({ activeStep: activeStep + 1 });
      this.allDataSave()
    } else if (activeStep === 2) {
      if (this.state.pet_type !== "daycare_services") {
        this.additional_serviceData();
        if (this.state.roomDetail.length === 0) {
          this.setState({
            errorMsg: "Please enter atleast one room type*",
            activeStep: activeStep
          })
        } else {
          this.handleValidation(this.state.activeStep)
        }
      } else {
        this.handleDayCareService();
      }
    } else if (activeStep === 3) {
      this.setState({ activeStep: activeStep + 1, errorMsg: "" }, () => this.state.roomDetail.forEach((value) => {
        this.validateStep3(value);
      }));
      this.createInitial();
      this.updateTimings();

    } else if (activeStep === 4) {
      this.handleUpdateOwnServices()
      // this.handleNextFor4(activeStep)

    } else {
      this.goToNextStep(activeStep);
    }
  };

  validateStep3 = async (value: any) => {
    // Calculate the total catRoomDog

    let selectedOptionMinimum;
    let selectedOptionMaximum;

    if (this.state.selectedOptionMin && this.state.selectedOptionMax) {
      selectedOptionMinimum = this.state.selectedOptionMin.split(' ')[0].slice(0, 2);
      selectedOptionMaximum = this.state.selectedOptionMax.split(' ')[0].slice(0, 2);
    }

    const header = {
      "Content-Type": "application/json",
    };

    const hotelId = JSON.parse(await getStorageData("hotelId"))

    setStorageData("hotel_id", hotelId)

    const httpBody = {

      "room": {
        "description": value.descriptionRoom,
        "room_for": value.selectedPet,
        "price": Number(value?.roomPricesArray?.length > 0 ? value.roomPricesArray[0]?.price : 0),
        "max_capacity": value.catRoomDog || value.addRoomDog,
        "additional_charges": Number(value.serviceFee) + Number(this.state.cleaningFee),
        "room_type": value.roomType,
        "pet_count": value.catRoomDog || value.addRoomDog,
        "add_pet": value.catRoomDog || value.addRoomDog,
        "min_stay": selectedOptionMinimum ? Number(selectedOptionMinimum) : null,
        "max_stay": selectedOptionMaximum ? Number(selectedOptionMaximum) : null,
        "advance_notice": this.state.advanceNotice,
        "notice_before": this.state.noticeBefore,
        "pet_room_prices": [...(value?.roomPricesArray?.map((value: { price: number }) => value?.price) || [])],
        "account_id": this.state.profileIdBo,
        "hotel_id": Number(hotelId),
        "num_rooms": value.addRoom,
        "num_dogs": value.addRoomDog * value.addRoom,
        "num_cats": value.catRoomDog * value.addRoom
      }


    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_custom_form/rooms"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    this.addRoomCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.allDataSave();
  };
  roomDataGet = () => {
    const loginRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.profileId = loginRequestMessage.messageId;
    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_custom_form/rooms?hotel_id=${this.state.hotelId}`
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.roomDataGetId = loginRequestMessage.messageId;
    runEngine.sendMessage(loginRequestMessage.id, loginRequestMessage);
  };

  removeRoomModel = (index: number) => {
    this.setState({
      removePetId: index,
      removeModel: true,
      modelOpen: true,
    });
  };

  removeRoom = () => {
    const { removePetId } = this.state;

    if (removePetId !== null && removePetId !== undefined) {
      this.setState((prevState) => ({
        roomDetail: prevState.roomDetail.filter((room, index) => index !== removePetId),
        modelOpen: false,
        removeModel: false,
        removePetId: null,
      }));
    }
  };






  additional_serviceData = () => {

    const loginRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.hotelEndPoint}/additional_services`
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );


    this.additional_serviceDataId = loginRequestMessage.messageId;
    runEngine.sendMessage(loginRequestMessage.id, loginRequestMessage);
  };
  validatePrices = () => {
    return this.state.roomDetail.some(room =>
      room?.roomPricesArray?.some((price: { price: string | null; }) => price.price === null || price.price === "")
    );
  }



  handleValidation = (activeStep: number) => {

    let isError = false

    this.state.roomDetail.forEach(room => {
      if (
        room.roomType === "" ||
        room.descriptionRoom === "" ||
        room.addRoom < 1 ||
        (room.addRoomDog < 1 && room.catRoomDog < 1)
      ) {
        isError = true;
      }
    })



    if (this.validatePrices()) {
      this.setState({ priceErrorMessage: "Please check the prices for the room", activeStep: activeStep })
    } else if (isError) {
      this.setState({
        errorMsgModel: configJSON.withoutpriceErrorMessage,
        errorMsgRoomType: "Please enter the room type name",
        errorMsgDescription: "Please enter the room description",
        errorMsgRoomCount: "Please specify the number of rooms at the property",
        errorPetCount: "Please specify the maximum number of pets in a room"
      })

    } else {
      isError = false
      this.setState({
        priceErrorMessage: "", activeStep: activeStep + 1,
        errorMsgModel: "",
        errorMsgRoomType: "",
        errorMsgPetType: "",
        errorMsgDescription: "",
        errorMsgRoomCount: "",
        errorPetCount: "",

      })

    }
  }

  uuidToNumber = () => {
    const uuid = uuidv4().replace(/-/g, '');
    return parseInt(uuid, 16);
  }

  getHotel = () => {
    const headers = {
      token: localStorage.getItem("authToken")
    };


    const hotelRequestMessage3 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    hotelRequestMessage3.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.hotelEndPoint}/${this.state.hotelId}`
    );
    hotelRequestMessage3.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    hotelRequestMessage3.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), "GET"
    );

    this.getHotelCallId = hotelRequestMessage3.messageId;
    runEngine.sendMessage(hotelRequestMessage3.id, hotelRequestMessage3);
  }

  dayCareServiceApiCall = async () => {

    const hotelId = await getStorageData("hotelId");
    setStorageData("hotel_id", hotelId)
    const formData = new FormData();
    formData.append(`day_care_service[spaces]`, this.state.spaces);
    formData.append(`day_care_service[price_for_one_day]`, this.state.oneTimePrice[0]);
    formData.append(`day_care_service[price_for_two_days]`, this.state.oneTimePrice[1]);
    formData.append(`day_care_service[price_for_three_days]`, this.state.oneTimePrice[2]);
    formData.append(`day_care_service[price_for_four_days]`, this.state.oneTimePrice[3]);
    formData.append(`day_care_service[price_for_five_days]`, this.state.oneTimePrice[4]);
    formData.append(`day_care_service[price_for_six_days]`, this.state.oneTimePrice[5]);
    formData.append(`day_care_service[price_for_seveen_days]`, this.state.oneTimePrice[6]);
    formData.append(`hotel_id`, hotelId);
    formData.append(`day_care_service[weekly_price_for_one_day]`, this.state.weeklyPrice[0]);
    formData.append(`day_care_service[weekly_price_for_two_days]`, this.state.weeklyPrice[1]);
    formData.append(`day_care_service[weekly_price_for_three_days]`, this.state.weeklyPrice[2]);
    formData.append(`day_care_service[weekly_price_for_four_days]`, this.state.weeklyPrice[3]);
    formData.append(`day_care_service[weekly_price_for_five_days]`, this.state.weeklyPrice[4]);
    formData.append(`day_care_service[weekly_price_for_six_days]`, this.state.weeklyPrice[5]);
    formData.append(`day_care_service[weekly_price_for_seveen_days]`, this.state.weeklyPrice[6]);
    this.dailyCareId = await this.apiCall({
      method: configJSON.callTypeApiValidateMobileNo,
      endPoint: configJSON.dayCareServiceEndpoint,
      body: formData,
      type: "formData"
    });
  };

  updateTimings = async () => {

    const hotelId = await getStorageData("hotelId");
    setStorageData("hotel_id", hotelId)
    const formData = new FormData();
    formData.append('hotel[check_in_start]', this.state.checkInFrom);
    formData.append('hotel[check_in_end]', this.state.checkInUtill);
    formData.append('hotel[check_out_start]', this.state.checkOutFrom);
    formData.append('hotel[check_out_end]', this.state.checkOutUtill);
    formData.append(`hotel[check_in_check_out_times_present]`, this.state.checkIn_Out.toString());
    formData.append(`hotel_id`, hotelId);
    this.apiCallIdUpdateTimings = await this.apiCall({
      method: configJSON.apiUpdateUserType,
      endPoint:  `${configJSON.hotelEndPoint}/${this.state.hotelId}`,
      body: formData,
      type: "formData"
    });
  };

  callOwnServiceApi = async () => {
    const hotelId = JSON.parse(await getStorageData("hotelId"));
    const body = {
      hotel_id: hotelId,
      hotel: {
        own_service: this.state.ownServices
      }
    };
    this.customServiceCallId = await this.apiCall({
      contentType: configJSON.contentTypeApiUpdateUser,
      method: configJSON.callTypeApiValidateMobileNo,
      endPoint: configJSON.customServiceEndpoint,
      body: body,
    });

  };
  createInitial = async () => {
    const formData = new FormData();
    const headers = {
      token: localStorage.getItem("authToken")
    };

    formData.append("hotel[more_than_30_days]", JSON.stringify(this.state.nightStay));
    formData.append("account_id", JSON.stringify(this.state.profileIdBo));

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.hotelEndPoint}/${this.state.hotelId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    this.hotelAddApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  apiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, endPoint, body, type } = apiData;
    let token = await getStorageData("authToken");
    const header = contentType ? {
      "Content-Type": contentType,
      token: token,
    } : {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData"
      ? requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  checkDayCareService = () => {
    return this.state.pet_type === "daycare_services" && this.state.activeStep === 2;
  };

  updatePrice = (event: ChangeEvent<HTMLInputElement>, priceIndex: number, occurance: string) => {
    const price = event.target.value.replace(/[^\d.]/g, '');
    if (occurance === "weekly") {
      this.setState(prevState => {
        const newState = [...prevState.weeklyPrice];
        newState[priceIndex] = price;
        return { weeklyPrice: newState };
      });
    } else {
      this.setState(prevState => {
        const newState = [...prevState.oneTimePrice];
        newState[priceIndex] = price;
        return { oneTimePrice: newState };
      });
    }

  };

  dayCareSuccessCallBack = (responseJson: DayCareServiceResponse & { error: string }) => {
    if (responseJson.day_care_service) {
      this.setState({ activeStep: this.state.activeStep + 1 });
    } else {
      this.setState({ errorMsg: responseJson.error });
    }
  }

  updateSpace = (event: { target: { value: string } }) => {
    const numberOfSpaces = event.target.value;
    if (configJSON.numberRegex.test(numberOfSpaces)) {
      this.setState({
        spaces: numberOfSpaces
      });
    }
  };

  getPetType = (petType: string) => {
    return petType === "AllPets" ? "All" : "EachPet";
  };

  handleSelectSevices = (service: ServiceItem) => {
    let randomID = service.attributes.id
    const serviceItem = {
      id: randomID,
      name: service.attributes.service_name,
      price: 0,
      hotel_id: 0,
      additional_service_id: 0,
      service_provided_type: "Daily",
      charge_type: "AllPets",
      type: 'new',
      alreadyCreated: false
    }
    let newService = {
      id: this.uuidToNumber(),
      name: "",
      price: 0,
      type: 'new',
      additional_service_id: 0,
      service_provided_type: "Daily",
      charge_type: "AllPets",
      hotel_id: 0,
      alreadyCreated: false
    };

    if (this.shouldAddNewService(service, randomID)) {
      this.setState({
        newelyCreateOwnServices: [...this.state.newelyCreateOwnServices, newService],
        ownServicesList: [...this.state.ownServicesList, newService]
      }, () => {
        this.handleClosePopup()
      });
    } else {
      this.setState({
        newAdditionalServices: [...this.state.newAdditionalServices, serviceItem],
        additionalServices: [...this.state.additionalServices, serviceItem]
      }, () => {
        this.handleClosePopup()
      });
    }


  }
  shouldAddNewService(service: any, randomID: number): boolean {
    const { service_name } = service.attributes;
    const keywords = ['create', 'specify'];

    return randomID === 9 || keywords.some(keyword => service_name.toLowerCase().includes(keyword));
  }

  handlePriceChange1 = (id: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { ownServicesList, additionalServices } = this.state
    const priceValue = event.target.value.replace("£", "").trim();
    const numericPrice = parseFloat(priceValue) || 0;
    const additionalService = additionalServices.find(service => service.id === id);
    const ownService = ownServicesList.find(service => service.id === id);
    if (additionalService) {
      this.additionalServiceChange(numericPrice, id, additionalService.service_provided_type, additionalService.charge_type);
    }
    if (ownService) {
      this.ownServiceChange(numericPrice, id, ownService.service_provided_type, ownService.charge_type);
    }
  }

  handleServiceType = (value: string, id: number) => {
    this.setState(prevState => {
      const updatedAdditionalServicesList = prevState.additionalServices.map(service => {
        if (service.id === id) {
          const updatedService = { ...service, service_provided_type: value };
          return updatedService;
        }
        return service;
      });
      const updatedOwnServices = prevState.ownServicesList.map(service => {
        if (service.id === id) {
          const updatedService = { ...service, service_provided_type: value };
          return updatedService;
        }
        return service;
      });

      return {
        additionalServices: updatedAdditionalServicesList,
        ownServicesList: updatedOwnServices
      };
    }, () => {
      const additionalService = this.state.additionalServices.find(service => service.id === id);
      const ownService = this.state.ownServicesList.find(service => service.id === id);

      if (additionalService) {
        this.additionalServiceChange(additionalService.price, additionalService.id, additionalService.service_provided_type, additionalService.charge_type);
      }

      if (ownService) {
        this.ownServiceChange(ownService.price, ownService.id, ownService.service_provided_type, ownService.charge_type);
      }
    });
  };

  handleChargeType = (value: string, id: number) => {
    this.setState(prevState => {
      const updatedAdditionalServicesList = prevState.additionalServices.map(service => {
        if (service.id === id) {
          const updatedCargeType = { ...service, charge_type: value };
          return updatedCargeType;
        }
        return service;
      });
      const updatedOwnServices = prevState.ownServicesList.map(service => {
        if (service.id === id) {
          const updatedCargeType = { ...service, charge_type: value };
          return updatedCargeType;
        }
        return service;
      });

      return {
        additionalServices: updatedAdditionalServicesList,
        ownServicesList: updatedOwnServices
      };
    }, () => {
      const myAdditionalServices = this.state.additionalServices.find(service => service.id === id);
      const myOwnService = this.state.ownServicesList.find(service => service.id === id);

      if (myAdditionalServices) {
        this.additionalServiceChange(myAdditionalServices.price, myAdditionalServices.id, myAdditionalServices.service_provided_type, myAdditionalServices.charge_type);
      }

      if (myOwnService) {
        this.ownServiceChange(myOwnService.price, myOwnService.id, myOwnService.service_provided_type, myOwnService.charge_type);
      }
    });
  };
  additionalServiceChange = (numericPrice: number, id: number, serviceProvidedType: string, chargeType: string) => {
    const { additionalServices } = this.state;
    const editedServiceIndex = additionalServices.findIndex(service => service.id === id);
    if (editedServiceIndex !== -1) {
      const editedService = { ...additionalServices[editedServiceIndex], price: numericPrice, service_provided_type: serviceProvidedType, charge_type: chargeType };
      if (editedServiceIndex !== -1 && additionalServices[editedServiceIndex].type === 'new') {
        this.setState(prevState => ({
          additionalServices: prevState.additionalServices.map(service => service.id === editedService.id ? editedService : service),
          newAdditionalServices: prevState.newAdditionalServices.map(service => service.id === editedService.id ? editedService : service),

        }));
      } else {
        const editedService = { ...additionalServices[editedServiceIndex], price: numericPrice, service_provided_type: serviceProvidedType, charge_type: chargeType };
        const editedAdditionalServices = [...this.state.editedAdditionalServices];
        editedAdditionalServices[editedServiceIndex] = editedService;
        this.setState(prevState => ({
          editedAdditionalServices,
          additionalServices: prevState.additionalServices.map(service => service.id === editedService.id ? editedService : service)
        }));
      }
    }
  }

  ownServiceChange = (numericPrice: number, id: number, serviceProvidedType: string, chargeType: string) => {
    const { ownServicesList } = this.state;
    const editedServiceIndex = ownServicesList.findIndex(service => service.id === id);
    if (editedServiceIndex !== -1) {
      const editedService = { ...ownServicesList[editedServiceIndex], price: numericPrice, service_provided_type: serviceProvidedType, charge_type: chargeType };
      if (editedServiceIndex !== -1 && ownServicesList[editedServiceIndex].type === 'new') {
        this.setState(prevState => ({
          ownServicesList: prevState.ownServicesList.map(service => service.id === editedService.id ? editedService : service),
          newelyCreateOwnServices: prevState.newelyCreateOwnServices.map(service => service.id === editedService.id ? editedService : service),
        }));
      } else {
        const editedService = { ...ownServicesList[editedServiceIndex], price: numericPrice, service_provided_type: serviceProvidedType, charge_type: chargeType };
        const editedOwnServices = [...this.state.editedOwnServices];
        editedOwnServices[editedServiceIndex] = editedService;
        this.setState(prevState => ({
          editedOwnServices,
          ownServicesList: prevState.ownServicesList.map(service => service.id === editedService.id ? editedService : service)
        }));
      }
    }
  }


  handleServiceNameChange = (id: number, newName: string) => {
    const { ownServicesList } = this.state;
    const editedServiceIndex = ownServicesList.findIndex(service => service.id === id);
    if (editedServiceIndex !== -1) {
      const editedService = { ...ownServicesList[editedServiceIndex], name: newName };
      if (ownServicesList[editedServiceIndex].type === 'new') {
        this.setState(prevState => ({
          ownServicesList: prevState.ownServicesList.map(service => service.id === editedService.id ? editedService : service)
        }));
      } else {
        const editedOwnServices = [...this.state.editedOwnServices]
        editedOwnServices[editedServiceIndex] = editedService;
        this.setState(prevState => ({
          editedOwnServices,
          ownServicesList: prevState.ownServicesList.map(service => service.id === editedService.id ? editedService : service)
        }));
      }

    }
  };
  handleUpdateOwnServices = async() => {
    const hotelID = await getStorageData('hotelId')
    
    const { additionalServices, ownServicesList,editedAdditionalServices,editedOwnServices,newAdditionalServices } = this.state
    const inputErrors: { [key: string]: string } = {};
    const ownServiceInputErrors: { [key: string]: string } = {};

    additionalServices.forEach((service, index) => {
      if (service.price === 0) {
        inputErrors[`price_${service.id}`] = "Service price greater than 0.";
      }
    });
    ownServicesList.forEach((service, index) => {
      if (service.price === 0) {
        ownServiceInputErrors[`price_${service.id}`] = "Service price greater than 0.";
      }
      if (service.name.trim() === '') {
        ownServiceInputErrors[`service_${service.id}`] = "Service name cannot be empty.";
      }
    });
    // Set error message state
    this.setState({ additionalInputErrors: inputErrors, ownServiceInputErrors: ownServiceInputErrors });

    // If there are errors, stop further execution
    if (Object.keys(inputErrors).length > 0) {
      return;
    }
    if (Object.keys(ownServiceInputErrors).length > 0) {
      return;
    }
    if(this.state.additionalServices.length===0||this.state.ownServicesList.length===0){
      handleNavigation("CancellationPolicyFirst",{activeStep:1,hotelId:hotelID},this.send,this.props)
    }

    if(editedAdditionalServices.length>0||editedOwnServices.length>0||newAdditionalServices.length>0){

      this.updateAdditionalServices();
    }


    {
      this.state.newelyCreateOwnServices && this.state.newelyCreateOwnServices.length > 0 &&
        this.createOwnServices()
    }

  }
  createOwnServices = async () => {
    const hotelID = await getStorageData('hotelId')
    let updatedOwnService = this.state.ownServicesList.map(service => ({
      name: service.name,
      service_provided_type: service.service_provided_type,
      price: service.price.toString(),
      charge_type: this.getPetType(service.charge_type === "PerPet" ? "EachPet" : "All"),
      alreadyCreated: service.alreadyCreated
    }));
    updatedOwnService = updatedOwnService.filter(service => !service.alreadyCreated)
    const httpBody = {
      "hotel_id": hotelID,
      "hotel": {
        "own_service": updatedOwnService
      }
    }
    this.addHotelCallId = await this.apiCall({
      method: "POST",
      endPoint: "bx_block_custom_form/hotels/create_your_own_services",
      contentType: "application/json",
      body: httpBody,
    });
  };

  handleDeleteService = (id: number, isCustom: boolean) => {
    const { additionalServices, ownServicesList } = this.state
    let deletedService: any = undefined;

    if (isCustom) {
      deletedService = ownServicesList.find(service => service.id === id);
    } else {
      deletedService = additionalServices.find(service => service.id === id);
    }
    if (isCustom) {
      this.setState(prevState => ({
        ownServicesList: prevState.ownServicesList.filter(service => service.id !== id),
      }), () => {
        this.deleteAdditionalServices(id, "own_services")
      })
    } else {
      this.setState(prevState => ({
        additionalServices: prevState.additionalServices.filter(service => service.id !== id),
      }), () => {
        this.deleteAdditionalServices(id, "additional_services")
      });
    }
  };

  deleteAdditionalServices = async (serviceId: number, serviceType: string) => {
    const hotelID = await getStorageData('hotelId')
    let updateHotelFormData = new FormData();
    if (serviceType === 'own_services') {
      updateHotelFormData.append('hotel[delete_create_your_own_services][]', serviceId.toString())

    } else {
      updateHotelFormData.append('hotel[delete_hotel_additional_services][]', serviceId.toString())

    }

    this.apiCallIdDeleteService = await this.apiCall({
      method: "PUT",
      endPoint: `${configJSON.hotelEndPoint}/${hotelID}`,
      type: "formData",
      body: updateHotelFormData,
    });
  }

  updateAdditionalServices = async () => {
    const hotelID = await getStorageData('hotelId')
    let updateHotelFormData = new FormData();
    this.state.editedOwnServices.length > 0 && this.state.editedOwnServices.forEach((service: CreateYourOwnService) => {
      if (!service) {
        return;
      }
      updateHotelFormData.append('hotel[create_your_own_services][][charge_type]', service.charge_type === "PerPet" ? "EachPet" : "All")
      updateHotelFormData.append('hotel[create_your_own_services][][id]', service.id.toString())
      updateHotelFormData.append('hotel[create_your_own_services][][name]', service.name)
      updateHotelFormData.append('hotel[create_your_own_services][][price]', service.price.toString())
      updateHotelFormData.append('hotel[create_your_own_services][][service_provided_type]', service?.service_provided_type)
    })
    this.state.editedAdditionalServices.length > 0 && this.state.editedAdditionalServices.forEach((service: HotelAdditionalService) => {
      if (!service) {
        return;
      }
      updateHotelFormData.append('hotel[hotel_additional_services][][charge_type]', service.charge_type === "PerPet" ? "EachPet" : "All")
      updateHotelFormData.append('hotel[hotel_additional_services][][id]', service.id.toString())
      updateHotelFormData.append('hotel[hotel_additional_services][][name]', service.name)
      updateHotelFormData.append('hotel[hotel_additional_services][][price]', service.price.toString())
      updateHotelFormData.append('hotel[hotel_additional_services][][service_provided_type]', service?.service_provided_type)
    })

    this.state.newAdditionalServices.forEach((value: HotelAdditionalService) => {
      if (value.type === 'new') {
        updateHotelFormData.append(`hotel[additional_services][${value.id}]`, JSON.stringify({ "price": value.price, "service_provided_type": value.service_provided_type, charge_type: value.charge_type === "PerPet" ? "EachPet" : "All" }))
      }

    });

    this.addHotelCallId = await this.apiCall({
      method: "PUT",
      endPoint: `${configJSON.hotelEndPoint}/${hotelID}`,
      type: "formData",
      body: updateHotelFormData,
    });
  };



  // Customizable Area End
}
