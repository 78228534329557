// Customizable Area Start
import React from "react";

import Footer from "../../../components/src/Footer.web";
import BussinessOwnerSideBar from "../../../components/src/SideBar"
import { theme } from "../../../components/src/theme";
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Menu, MenuItem, Select, Theme, createStyles, withStyles } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
import SchedulingController, {
    Props,
  configJSON,
} from "./SchedulingController";
import CalendarView from "../../../components/src/CalendarView.web";
import { arrowBack, arrowNext } from "./assets";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
// Customizable Area End

// Customizable Area Start
export class Scheduling extends SchedulingController {
  constructor(props: Props) {
    super(props);
  }

// Customizable Area End

// Customizable Area Start
  render() {
    const { currentDate } = this.state;
    const {classes} = this.props
    const startOfWeek = new Date(currentDate);
    const day = (startOfWeek.getDay() + 6) % 7; 
    const diff = -day; 
    startOfWeek.setDate(currentDate.getDate() + diff);
    const daysOfWeek = Array.from({ length: 7 }, (_, i) => this.getFormattedDay(startOfWeek, i));
    const weekRange = this.getWeekRange(currentDate);
    const open = Boolean(this.state.anchorEl)
    return (
      <>
      <NavigationMenu active={3} navigation={this.props.navigation} id="" />
      <Box
        style={{
          marginLeft: "140px",
          flexGrow: 1,
          backgroundColor: theme.palette.primary.main,
          padding: "24px",
          overflowY: "auto",
          fontFamily: "Inter",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" marginBottom={2} 
            style={{
              gap:"2px", 
              justifyContent: "space-between",
              marginBottom:'35px'
              }}>
              <Box style={{display:"flex"}}>
        <div>
        <Button
              data-test-id="room"
          variant="outlined"
          onClick={this.handleClick}
          style={{fontSize:"12px",
            textTransform: "capitalize",
            justifyContent:"space-between",
            paddingLeft: '22px'
          }}
          endIcon={<ArrowDropDownIcon />}
          className={classes.menuItemStyle}>
          {this.getSelectedRoomsLabel()}
        </Button>
        <Menu 
        data-test-id="anchorEl-id" anchorEl={this.state.anchorEl} 
        
        open={open} onClose={this.handleClose}
        PaperProps={{ className: classes.menuPaper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ top: 55 }}
         >
          <FormGroup>
            <MenuItem>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.tempRooms.allRooms}
                    onChange={this.handleAllRoomsChange}
                    name="allRooms"
                    data-test-id="allroom-change"
                  />
                }
                label="All Rooms"
              />
            </MenuItem>
            {this.state.isUnique.map((room:any) => (
              <MenuItem key={room}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.tempRooms[room]}
                      onChange={this.handleRoomChange}
                      name={room}
                      data-test-id="room-checkbox"
                    />
                  }
                  label={room}
                />
              </MenuItem>
            ))}
          </FormGroup>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button style={{marginRight:"2px"}} data-test-id="cancel-filter" variant="outlined" onClick={this.handleClose}>
              Cancel
            </Button>
          <Button 
            variant="contained" 
            data-test-id="apply-filter"
            onClick={this.handleApply}
            disabled={this.getTempSelectedRoomsLabel()==='No Rooms Selected'}
            style={{
              color: 'white',
              background: this.getTempSelectedRoomsLabel() === 'No Rooms Selected' ? "gray" : "rgb(101, 201, 241)"
            }}
          >Apply</Button>
          </div>
        </Menu>
      </div>
              <Select
              data-test-id="property"
                   value={this.state.selectedProperty}
                   onChange={this.handlePropertyChange}
                   defaultValue={'All Properties'}
                    variant="outlined"
                    className={classes.menuItemStyle}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                      PaperProps: {
                        className: classes.paperStyle,
                      },
                    }}
                  >
               <MenuItem 
               className={classes.menuItemRoot}
               style={{fontWeight:700}} 
               value="All Properties">All Properties</MenuItem>
                  {this.state.filteredData.map((hotel,idx) => (
                    <MenuItem className={classes.menuItemRoot} key={idx} value={hotel.attributes.name}>
                      {hotel.attributes.name}
                    </MenuItem>
                    ))}
              </Select>
              {!this.state.isWeek && <Button
              onClick={this.handleTodayClick}
              style={{
              color: 'rgb(228, 36, 139)',
              fontSize: "12px",
              border: "1px solid rgba(178, 228, 248, 1)",
              fontWeight:700,
              height: "44px",
              minWidth: "100px",
              textTransform: "uppercase",
              borderRadius: "44px",
              backgroundColor: "#ffffff",
              alignItems: "center",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)"
          }}
          data-test-id="today-btn"
        >
          Today
        </Button>}
              </Box>
              <Box display="flex" alignItems="center" >
                <Button
                data-test-id="previous-date"
                  onClick={() => this.handleDateChange(-1)}
                  style={{
                    padding: "3px",
                    fontSize: "14px",
                    background: "transparent",
                    border: "none",
                    gap:"1px"
                  }}
                >
                   <img src={arrowBack} 
                   alt="Previous" 
                   style={{ marginRight: "5px" }} />
                </Button>
                <Button
                  style={{
                    padding: "3px",
                    fontSize: "15px",
                    background: "transparent",
                    border: "none",
                  }}
                >
                    {this.state.isWeek ? weekRange : this.formatDate(currentDate)}
                </Button>
                <Button
                data-test-id="next-date"
                   onClick={() => this.handleDateChange(1)}
                  style={{
                    padding: "3px",
                    fontSize: "14px",
                    background: "transparent",
                    border: "none",
                    gap:"1px"
                  }}
                >
                   <img src={arrowNext} 
                   alt="Previous" 
                   style={{ marginRight: "5px" }} />
                </Button>
              </Box>
              <Box style={{display:"flex"}}>
              <Select
              data-test-id="pets"
              value={this.state.selectedPet}
              onChange={this.handlePetChange}
              variant="outlined"
              defaultValue={'All Pets'}
              className={classes.menuItemStyle}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                PaperProps: {
                  className: classes.paperStyle,
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              >
                  <MenuItem 
                  className={classes.menuItemRoot} 
                  style={{fontWeight:700}} value="All Pets">All Pets</MenuItem>
                  <MenuItem className={classes.menuItemRoot} value="Cat">Cats</MenuItem>
                  <MenuItem className={classes.menuItemRoot} value="Dog">Dogs</MenuItem>
                </Select>
          <Button
            style={{
              padding: "5px",
              fontSize: "12px",
              border: "1px solid rgba(178, 228, 248, 1)",
              cursor: "pointer",
              height: "44px",
              minWidth: "175px",
              borderRadius: "44px",
              backgroundColor: "#ffffff",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              textTransform: "capitalize",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)"
            }}
          >
                  <div
                    style={{
                      color: this.state.isWeek ? "rgb(228, 36, 139)" : "black",
                      fontWeight: this.state.isWeek ? 700 : 400,
                  cursor: "pointer"
                }}
                onClick={() => this.handleViewChange('week')}
               data-test-id="week-view"
              >
                Week
              </div>
              <div
                style={{
                  color: !this.state.isWeek ? "rgb(228, 36, 139)" : "black",
                  fontWeight: !this.state.isWeek ? 700 : 400,
                  cursor: "pointer"
                }}
               data-test-id="day-view"
                onClick={() => this.handleViewChange('day')}
              >
                Day
              </div>
            </Button>
            </Box>
            </Box>
           <CalendarView 
           daysOfWeek={daysOfWeek}
           currentDate={this.state.currentDate}
           isWeek={this.state.isWeek}
           startOfWeek={startOfWeek}
           calendarData={this.state.calendarData as []}
           calendarDayData={this.state.calendarDayData as []}
           pagLoader={this.state.pagLoader}
           />
          </Grid>
          <Grid item xs={12} className={classes.footerLayout}>
            <Footer footerNavigationData={this.state.socialLinksData} boAndPOLinks={this.state.poBOLinksData} />
            </Grid>
        </Grid>
      </Box>
    </>
    );
  }
}
// Customizable Area End

// Customizable Area Start
export const Schedulingclasses = (theme: Theme) =>
  createStyles({
      bgLayout: {
          backgroundColor: "#E0F4FC",
          minHeight: "100vh",
          width: "100%",
      },
      propertyLayout: {
          padding: "0 40px"
      },
      addBtn: {
          textTransform: 'none',
          fontSize: 16,
          fontWeight: theme.typography.fontWeightBold,
          color: theme.palette.secondary.main,
          borderRadius: 8,
          "&:hover"
          : {
              color: theme.palette.secondary.main,

          }

      },
      headingLayout: {
          marginTop: 20,
          background: " #F0FAFE",
          height: "54px",
          borderRadius: 8
      },
      hotelListLayout: {
          marginTop: 10,
          background: theme.palette.common.white,
          height: 86,
          borderRadius: 8,
          cursor: 'pointer'
      },
      headingLabel: {
          color: theme.palette.common.black,
          fontWeight: theme.typography.fontWeightRegular,
          textAlign: 'center'
      },
      textLabel: {
          color: theme.palette.common.black,
          fontWeight: theme.typography.fontWeightBold,
          textAlign: 'center'
      },
      propertyName: {
          color: theme.palette.common.black,
          fontWeight: theme.typography.fontWeightBold,

      },
      hotelsListLayout: {
          // height: '500px',

      },
      ActiveText: {
          color: "#059669",
          fontWeight: theme.typography.fontWeightBold,
          textAlign: 'center'
      },
      warningText: {
          color: theme.palette.warning.main,
          fontWeight: theme.typography.fontWeightBold,
          textAlign: 'center'

      },
      draftText: {
          color: theme.palette.text.disabled,
          fontWeight: theme.typography.fontWeightBold,
          textAlign: 'center'

      },
      hotelImage: {
          borderRadius: 8,
          height: 52,
          width: '100%',
          marginLeft: 10

      },
      notFoundLayout: {
          margin: '20px 0'
      },
      searchIconLayout: {
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: theme.palette.common.white,
          borderRadius: 4,
          width: "100%",
          height: 64,
          [theme.breakpoints.down('xs')]: {
              width: "100%",
              height: 52
          }
      },
      filterLayout: {
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          background: theme.palette.common.white,
          borderRadius: 4,
          width: "100%",
          height: 64,
          [theme.breakpoints.down('xs')]: {
              width: "100%",
              height: 52
          },
      },
      filterIcon: {
          margin: '0 20px'
      },
      iconImageSearch: {
          width: 35,
          height: 35,
          [theme.breakpoints.down('xs')]: {
              width: 24,
              height: 24
          }
      },
      inputSearch: {
          "& .MuiInputBase-root": {
              fontFamily: "Inter",
              color: "#000000",
              backgroundColor: "#ffffff",
              height: "25px"
          },
      },
      searchHotelLayout: {
          padding: '10px',
          backgroundColor: theme.palette.common.white,
          borderRadius: 4,
          minHeight: 64,
          textTransform: "none",
      },
      locationText: {
          paddingLeft: 15,
          color: theme.palette.common.black,
          fontWeight: theme.typography.fontWeightBold,
      },
      searchBar: {
          marginTop: 50,
          minHeight: "80px",
          backgroundColor: theme.palette.info.main,
          borderRadius: 8,
          margin: 'auto',
      },
      addBtnLayout: {
          margin: "20px 0"
      },
      content: {
          marginLeft: "140px", // Adjust based on the width of your sidebar
          flexGrow: 1,
          backgroundColor: theme.palette.primary.main,
          padding: theme.spacing(3),
          overflowY: "auto", // Allow main content area to scroll
      },
      root: {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh', // Ensure that the container takes at least the full viewport height
      },
      textLocation: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: theme.palette.common.black,
          fontWeight: theme.typography.fontWeightRegular,
      }, errorText: {
          color: theme.palette.error.main,
          fontWeight: theme.typography.fontWeightBold,
          textAlign: 'center'

      },
      menuItemStyle: { fontWeight: 700,
        padding: '10px',
        fontSize: '12px',
        border: '1px solid rgba(178, 228, 248, 1)',
        cursor: 'pointer',
        height: '44px',
        minWidth: '175px',
        borderRadius: '44px',
        backgroundColor: 'white',
        marginRight: '25px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',},
        paperStyle: {
          border: '1px solid rgba(178, 228, 248, 1)',
          borderRadius: '8px',
          paddingLeft: '8px',
          paddingRight: '8px',
        },
        menuItemRoot: {
          borderBottom: '1px solid rgba(178, 228, 248, 1)',
          paddingLeft: '12px',
          paddingRight: '12px',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'rgba(178, 228, 248, 0.1)', 
          },
        },
        footerLayout: {
          marginTop: '150px'
        },
        menuActions: {
          display: 'flex',
          justifyContent: 'space-between',
          padding: '8px 16px',
        },
        menuPaper: {
          borderRadius: '10px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          padding: '10px',
        },
  })
export default withStyles(Schedulingclasses)(Scheduling);

// Customizable Area End
