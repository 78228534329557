import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Additionalservicesforcat, FilterButtons, PetAttributes, ReservationsData, RoomTypes, Rooms, AdditionalServicesforPets } from "./types";
import { WithStyles } from "@material-ui/core";
import React from "react";
import { DateObject } from "react-multi-date-picker";

// Customizable Area Start
import { PaymentDetails, RoomDetails } from "./ReservationBusinessOwnerController.web";

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start
    open: boolean;
    dailogType: string;
    getDailogStatus: Function
    getRejectAction: Function;
    getRejectMessage: Function;
    petDetails: PetAttributes;
    getFilterParams: Function;
    reservationDetails:ReservationsData
    upcomingStatusAction:Function;
    upcomingIndex:number
    saveOrderId:string|number;
    listOfRooms: RoomDetails[];
    roomListBo: RoomDetails[];
    openRoomTypes: boolean;
    params: any;
    selectedRoomType: string;
    selectedRoomCount: number;
    openCalenderStatus: boolean;
    inputFieldDate: string;
    selectRoomType: (selectedType:string) => void;
    handleRoomSelect: (roomCount: number) => void;
    openRoomType:() => void;
    closeRoomTypes: () => void;
    closeSelectDate: () => void;
    clearDates: () => void;
    openSelectDate: () => void;
    handleDateChange: (values: DateObject[]) => void;
    addPets:() => void;
    minusPets: () => void;
    handlePriceInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selectedRange: DateObject[];
    numberOfPets: number;
    price: {
        min: string;
        max: string;
    },
    handleClear:() => void;
    closeDate:()=> void;
    paymentDetails:PaymentDetails;
    popUpBtnText:string
    openCommonDailog:(filterType: string, orderId: string | number, index?: number)=>void
    
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    FilterButtons: FilterButtons[];
    selectedFilter: string;
    searchStatus: boolean;
    searchValue: string;
    statusDropDown: boolean;
    statusDropDownValues: number;
    dailogStatus: boolean;
    StatusValue: string
    rooms: Rooms[];
    emergencycontactDropDownStatus: boolean
    legalContactDropDownStatus: boolean
    medicalNotesDropDownStatus: boolean
    vetClinicDropDownStatus: boolean;
    rejectMessage: string;
    errorRejectMessage: string
    openCalenderStatus: boolean
    selectedRange: DateObject[];
    startDate: string;
    endDate: string;
    dateRange: string,
    price: {
        min: string;
        max: string;
    }
    numberOfPets: number
    openRoomTypes: boolean
    RoomTypes: RoomTypes[]
    selectedRoomType: string;
    inputFieldDate: string;
    totalPrice: number;
    params: any
    selectedRoomCount: number
    isSelectedRoomCount: boolean;
    numberOfWeeks: number;
    daycarePrice: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ReservationDailogController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiCallIdGetAllRooms: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        const date = new DateObject()
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            FilterButtons: [
                { filterId: 1, filterName: "Pending", filterValue: "" },
                { filterId: 2, filterName: "Completed", filterValue: "" },
                { filterId: 3, filterName: "Cancelled", filterValue: "" },
                { filterId: 4, filterName: "Blocked", filterValue: "" }
            ],
            selectedFilter: "Pending",
            searchStatus: false,
            searchValue: "",
            statusDropDown: false,
            statusDropDownValues: 0,
            dailogStatus: false,
            StatusValue: "Pending...",
            rooms: [
                { id: 1, roomCount: 0 }, { id: 2, roomCount: 1 }, { id: 3, roomCount: 2 },
                { id: 4, roomCount: 3 }, { id: 5, roomCount: 4 }, { id: 6, roomCount: 5 },
                { id: 7, roomCount: 6 }, { id: 8, roomCount: 7 }, { id: 8, roomCount: "8+" }
            ],
            selectedRoomCount:0,
            isSelectedRoomCount: false,
            emergencycontactDropDownStatus: false,
            legalContactDropDownStatus: false,
            medicalNotesDropDownStatus: false,
            vetClinicDropDownStatus: false,
            rejectMessage: "",
            errorRejectMessage: "",
            openCalenderStatus: false,
            selectedRange: [],
            startDate: date.format('DD/MM/YYYY'),
            endDate: date.format('DD/MM/YYYY'),
            dateRange: "",
            price: {
                min: "",
                max: ""
            },
            numberOfPets: 0,
            openRoomTypes: false,
            RoomTypes: [
                { id: 1, name: "Single Suite" },
                { id: 2, name: "Double Suite" },
                { id: 3, name: "Luxury Villa" }
            ],
            selectedRoomType: "",
            inputFieldDate: "",
            params: {
                min: "",
                max: "",
                date: "",
                rommType: "",
                numberofPets: "",
                num_rooms: 0,


            },
            totalPrice: 0,
            numberOfWeeks: 1,
            daycarePrice: ""
            // Customizable Area "End"
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        this.setState({ dateRange: `${this.state.startDate} - ${this.state.endDate}` })
        this.checkDays();
        if(this.props.reservationDetails?.attributes?.day_care_services){
            this.getDayCareDisplayPrice();
        }
    }
    emergencyContactDropDown = () => {
        this.setState({ emergencycontactDropDownStatus: !this.state.emergencycontactDropDownStatus })
    }
    legalContactDropDown = () => {
        this.setState({ legalContactDropDownStatus: !this.state.legalContactDropDownStatus })
    }
    medicalDropDown = () => {
        this.setState({ medicalNotesDropDownStatus: !this.state.medicalNotesDropDownStatus })
    }
    vetClinicDropDown = () => {
        this.setState({ vetClinicDropDownStatus: !this.state.vetClinicDropDownStatus })
    }


    closeCommonDailog = () => {
        this.setState({ dailogStatus: false }, () => {
            this.props.getDailogStatus(this.state.dailogStatus)
        })
    }
    rejectAction = () => {
        const { rejectMessage } = this.state;
        if (rejectMessage.trim() === '') {
            this.setState({
                errorRejectMessage: 'Reject message is required.'
            });
        } else {
            // Clear error message if input is not empty
            this.setState({
                errorRejectMessage: '', StatusValue: 'REJECT'
            }, () => {
                this.props.getDailogStatus(false)
                this.props.getRejectAction(this.state.StatusValue)
            });
        }
    }
    handleRejectMessage = (event: React.ChangeEvent<HTMLInputElement>) => {

        this.setState({ rejectMessage: event.target.value }, () => {
            this.props.getRejectMessage(this.state.rejectMessage)
        })

    }
    openSelectDate = () => {
        this.setState({ openCalenderStatus: true })
    }
    closeSelectDate = () => {
        this.setState({ openCalenderStatus: false })
    }

    handleSearch = () => {
        let paramsString = "";
        Object.keys(this.props.params).forEach((param) => {
            if (paramsString === "" && this.props.params[param] != "") {
                paramsString = this.props.params[param]

            } else if (paramsString && this.props.params[param]) {
                paramsString += '&' + this.props.params[param];

            }
        })

        this.props.getFilterParams(paramsString)
        this.closeCommonDailog()

    }


    checkinRes=()=>{
        if(this.props.popUpBtnText==="Reject" ||this.props.popUpBtnText==="Cancelled"){
            this.props?.openCommonDailog('reject',this.props.saveOrderId,this.props.upcomingIndex)
        }else{
            this.closeCommonDailog()
            this.props.upcomingStatusAction(this.props.popUpBtnText,this.props.saveOrderId,this.props.upcomingIndex)
        }
    }

    getTotalprice = (daysDifference: number) => {
        const dogRoomPrice = this.props.reservationDetails.attributes.room_for_dogs.reduce(
            (dogRoomSum, roomDetail) => dogRoomSum + roomDetail.price * daysDifference, 
            0
          );        
          const catRoomPrice = this.props.reservationDetails.attributes.room_for_cats.reduce(
            (catRoomSum, roomDetail) => catRoomSum + roomDetail.price * daysDifference, 
            0
          );
          if(this.props.popUpBtnText==="Check-Out"){
            return (dogRoomPrice + catRoomPrice + this.getServiceCost()).toFixed(2);
          }else{
              return (this.props.reservationDetails.attributes.total_charges+dogRoomPrice + catRoomPrice + this.getServiceCost()).toFixed(2);
          }
    };

    getRoomType = (roomId: number) => {
        const selectedRoom = this.props.listOfRooms.find(room => room.id === roomId);
        return selectedRoom?.room_type;
    };

    getPrice = (price: number, daysDifference: number) => {
        return (price * daysDifference).toFixed(2);
    };

    getDayCarePrice = (price: string | null, pets: number) => {
        return Number(price) * pets * this.state.numberOfWeeks;
    };

    getTotalDayCarePrice = (price: string | null, pets: number) => {
        return Number(price) * pets * this.state.numberOfWeeks + this.getServiceCost()+this.props.reservationDetails.attributes.total_charges;
    };

    getDogCount = (dogs: number) => {
        const dogText = dogs > 0 ? "Dog" : "";
        return `${dogs === 0 ? "" : dogs} ${dogs > 1 ? "Dogs" : dogText}`;
    };

    getCatCount = (cats: number) => {
        const catText = cats > 0 ? "Cat" : "";
        return `${cats === 0 ? "" : cats} ${cats > 1 ? "Cats" : catText}`;
    };

    getServiceTotalDaily = (price: number, pets: number , totalDay: number) => {
        return price* pets * totalDay;
    };

    getServiceTotalDailyAll = (price: number, totalDay: number) => {
        return price* totalDay;
    };
    
    getServiceOneTime = (price: number, pets: number) => {
        return price * pets;
    };

    getPetCount = (dogcount1: number, catcount1: number) => {
        return catcount1 + dogcount1;
    };

    getServiceCost = () => {
        const { reservationDetails } = this.props
        const aditionalService = reservationDetails.attributes.additional_services_for_dog || [];
        const ownService = reservationDetails.attributes.own_services_for_dog || [];
        const checkInDate = new Date(reservationDetails.attributes.check_in_date);
        const checkOutDate = new Date(reservationDetails.attributes.check_out_date);

        const timeDifference = Number(checkOutDate) - Number(checkInDate)

        const daysDifference: number = timeDifference / (1000 * 60 * 60 * 24);

        const dogCount = (reservationDetails?.attributes.number_of_dogs);
        const catCount = (reservationDetails?.attributes.number_of_cats);

        let totalCost = 0;
        aditionalService.forEach((value: AdditionalServicesforPets) => {
            if(value.service_provided_type === "Daily" && value.isAllPets !== "All Pets"){
                totalCost = totalCost + this.getServiceTotalDaily(value.service.price, value.pets.length, daysDifference );
            } else if(value.service_provided_type !== "Daily" && value.isAllPets !== "All Pets"){
                totalCost = totalCost + this.getServiceOneTime(value.service.price, value.pets.length );
            }  else if(value.service_provided_type === "Daily" && value.isAllPets === "All Pets"){
                totalCost = totalCost + this.getServiceTotalDaily(value.service.price, dogCount+catCount, daysDifference );
            }  else {
                totalCost = totalCost + this.getServiceOneTime(value.service.price, dogCount+catCount );
            }
        });

        ownService.forEach((value: AdditionalServicesforPets) => {
            if(value.service_provided_type === "Daily" && value.isAllPets === "All Pets"){
                totalCost = totalCost + this.getServiceTotalDaily(value.service.price, dogCount+catCount, daysDifference );
            } else if(value.service_provided_type === "Daily" && value.isAllPets !== "All Pets"){
                totalCost = totalCost + this.getServiceTotalDaily(value.service.price, value.pets.length, daysDifference );
            } else if(value.service_provided_type !== "Daily" && value.isAllPets !== "All Pets"){
                totalCost = totalCost + this.getServiceOneTime(value.service.price, value.pets.length );
            } else {
                totalCost = totalCost + this.getServiceOneTime(value.service.price, dogCount+catCount );
            }
        });
        return totalCost;
    };

    getNumberOfWeeks = () => {
        if(this.props.reservationDetails.attributes.day_care_service_type === "Repeat_Weekly"){
          if(this.state.numberOfWeeks > 1){
            return `X ${this.state.numberOfWeeks} Weeks`
          } 
          else {
            return "X 1 Week"
          }
        }
        return "";
      };

      checkDays = () => {

        const checkInDate = new Date(this.props.reservationDetails?.attributes?.check_in_date);
        const checkOutDate = new Date(this.props.reservationDetails?.attributes?.check_out_date);

        const timeDifference = Number(checkOutDate) - Number(checkInDate)

        const daysDifference: number = timeDifference / (1000 * 60 * 60 * 24);
        const dayList = [0, 0, 0, 0, 0, 0, 0];
        const startDay = new Date(this.props.reservationDetails?.attributes?.check_in_date).getDay();
        const totalDay: number = Math.floor(daysDifference);
        let date = 0, getday = startDay;
        while (date <= totalDay) {
          dayList[getday] += 1;
          getday = (getday + 1) % 7;
          date += 1;
        }
        const daySet: Set<number> = new Set();
        dayList.forEach((dayFreq: number, dayIndex: number) => {
          dayIndex = dayIndex > 0 ? dayIndex - 1 : 6;
          if (this.props.reservationDetails?.attributes?.days.includes(String(dayIndex))) {
            daySet.add(dayFreq);
          }
        });
        this.setState({ 
          numberOfWeeks: Array.from(daySet)[0],
        });
      };

    getDayCareDisplayPrice = () => {
        const oneTimePrice = [
            "0",
            this.props.reservationDetails.attributes.day_care_services.price_for_one_day,
            this.props.reservationDetails.attributes.day_care_services.price_for_two_days,
            this.props.reservationDetails.attributes.day_care_services.price_for_three_days,
            this.props.reservationDetails.attributes.day_care_services.price_for_four_days,
            this.props.reservationDetails.attributes.day_care_services.price_for_five_days,
            this.props.reservationDetails.attributes.day_care_services.price_for_six_days,
            this.props.reservationDetails.attributes.day_care_services.price_for_seveen_days
        ];
        const weeklyPrice = [
            "0",
            this.props.reservationDetails.attributes.day_care_services.weekly_price_for_one_day,
            this.props.reservationDetails.attributes.day_care_services.weekly_price_for_two_days,
            this.props.reservationDetails.attributes.day_care_services.weekly_price_for_three_days,
            this.props.reservationDetails.attributes.day_care_services.weekly_price_for_four_days,
            this.props.reservationDetails.attributes.day_care_services.weekly_price_for_five_days,
            this.props.reservationDetails.attributes.day_care_services.weekly_price_for_six_days,
            this.props.reservationDetails.attributes.day_care_services.weekly_price_for_seveen_days
        ];
        const totalDays = this.props.reservationDetails.attributes.days.length;
        let displayPrice ;
        if(this.props.reservationDetails.attributes.day_care_service_type === "Repeat_Weekly"){
            displayPrice = weeklyPrice[totalDays];
        } else {
            displayPrice = oneTimePrice[totalDays];
        }
        this.setState({daycarePrice: displayPrice});
    };

    getFloatingValue(price: string | undefined, stripeCharges: number){
        if(price && stripeCharges){
            return "£"+(Number(price.split("£")[1]) +stripeCharges).toFixed(2);
        }
    };

    getTotalFloatingValue(price: string | undefined, stripeCharges: string | undefined){
        if(price && stripeCharges){
            return "£"+(Number(price.split("£")[1]) + Number(stripeCharges.split("£")[1])).toFixed(2);
        }
    };

    calculateServicePrice = (catServices: Additionalservicesforcat[], totalcalculatecatservice: number, petCount: number) => {
        catServices && catServices.forEach((value: { charge_type: string; price: number; }) => {
            if(value.charge_type === "All"){
                totalcalculatecatservice = totalcalculatecatservice + value.price;
            } else{
                totalcalculatecatservice = totalcalculatecatservice + value.price * petCount;
            }
        });
        return totalcalculatecatservice;
    };

    getPetsNames = (petsList: string[]) => {
        let petNameList = "";
        petsList.forEach((name: string, catNameIndex: number) => {
            if (catNameIndex === petsList.length - 1 && catNameIndex !== 0) {
              petNameList = petNameList + " and " + name;
            } else if (catNameIndex === 0) {
              petNameList = petNameList + name;
            } else {
              petNameList = petNameList + ", " + name;
            }
          })
        return `( ${petNameList} )`;
    };
    
    getQuestions = () => {
        const questionObject = this.props.reservationDetails.attributes.additional_question_and_answer;
        if(questionObject?.additional_question1?.additional_question_one === "" && questionObject?.additional_question2?.additional_question_two === "" && questionObject?.additional_question3?.additional_question_three === ""){
            return false;
        } 
        return true;
    };

    getFinalPrice = (reservationDetails: ReservationsData, daysDifference: number) => {
        return reservationDetails.attributes.hotel_information.pet_type === "daycare_services" ?
                            `£${this.getTotalDayCarePrice(this.state.daycarePrice, reservationDetails.attributes.pets.data.length)}`
                            : `£${this.getTotalprice(daysDifference)}`
    }
    // Customizable Area End
}